import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Carousel, {
	ICarouselProps,
	CarouselItem,
} from 'tbk-components/src/components/Carousel';
import Image, { IImage } from 'tbk-components/src/components/Image';

export interface IFullWidthGalleryProps extends IBaseElementProps {
	/**
	 * Full Width Gallery Images
	 */
	images?: IImage[];
	/**
	 * @control uniqueid
	 * @prefix full-width-gallery
	 */
	id?: string;
}

/**
 * Full Width Gallery
 * @icon images-alt
 */
const FullWidthGallery: React.FC<IFullWidthGalleryProps> = ({
	className,
	classNames = [],
	images = [],
	id,
}) => {
	return (
		<div className={className || cn('full-width-gallery', ...classNames)}>
			<Carousel
				id={id ? `${id + '-gallery'}` : null}
				className={className || cn('full-width-gallery__carousel')}
				showBullets={false}
			>
				{images.map((image, i) => (
					<CarouselItem key={i}>
						<Image className="img-fluid" {...image} />
					</CarouselItem>
				))}
			</Carousel>
		</div>
	);
};

export default FullWidthGallery;

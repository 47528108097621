import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { ILink } from 'tbk-components/src/components/Button';
import CustomButton from '../CustomButton';
import useMobileViewport from '../../utils/useMobileViewport';
import { ICustomizationOptions } from '../../types';

export interface IAlternateTextImageProps
	extends ICustomizationOptions,
		Omit<IBaseElementProps, 'id'> {
	sectionId?: string;
	heading?: string;
	/**
	 * @control richtext
	 */
	paragraph?: string;
	link?: ILink;
	image?: IImage;
	contentPosition?: 'left' | 'right';
}

/**
 * Alternate Text Image
 * @block
 * @requiresHooks
 * @icon image-flip-horizontal
 */
const AlternateTextImage: React.FC<IAlternateTextImageProps> = ({
	heading,
	paragraph,
	link,
	image,
	className,
	contentPosition = 'left',
	classNames = [],
	sectionBackgroundColor = 'white',
	sectionId,
	buttonColor = 'primary',
	textColor = 'black',
}) => {
	const isMobile = useMobileViewport(1024);

	let flexDirection;

	if (!isMobile) {
		flexDirection = contentPosition === 'right' ? 'row-reverse' : 'row';
	} else {
		flexDirection =
			contentPosition === 'right' ? 'column-reverse' : 'column';
	}

	return (
		<div
			className={
				className ||
				cn(
					'alternate-text-image',
					`bg-${sectionBackgroundColor}`,
					...classNames,
				)
			}
			id={sectionId}
		>
			<div
				className="alternate-text-image__container"
				style={{ flexDirection }}
			>
				<div
					className={cn(
						`alternate-text-image__content`,
						!image && 'no-image',
					)}
				>
					<div className={cn(`alternate-text-image__top-content`)}>
						{heading && (
							<h2
								className={`alternate-text-image__heading text-${textColor}`}
							>
								{heading}
							</h2>
						)}
						{paragraph && (
							<div
								className={`alternate-text-image__paragraph text-${textColor}`}
								dangerouslySetInnerHTML={{ __html: paragraph }}
							/>
						)}
					</div>
					{(link?.href || link?.label) && (
						<div className="alternate-text-image__bottom-content">
							<CustomButton
								{...link}
								classNames={['alternate-text-image__link']}
								buttonType="Button"
								size={isMobile ? 'sm' : 'lg'}
								color={buttonColor}
							/>
						</div>
					)}
				</div>
				{image && (
					<div className="alternate-text-image__image">
						<Image {...image} />
					</div>
				)}
			</div>
		</div>
	);
};

export default AlternateTextImage;

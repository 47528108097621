import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import Collapse from 'bootstrap/js/dist/collapse';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { BgColor } from 'tbk-components/src/components/Core/colors';
import { OffcanvasButton } from 'tbk-components/src/components/Offcanvas';
import Image, { IImage } from 'tbk-components/src/components/Image';
import Button, { ILink } from 'tbk-components/src/components/Button';
import useMobileViewport from '../../utils/useMobileViewport';

export interface INavBarProps extends IBaseElementProps {
	background?: BgColor;
	logo?: IImage;
	logoMobile?: IImage;
	ctaMobile?: ILink;
	logoURL?: string;
	useCollapse?: boolean;
	useOffcanvas?: boolean;
	isSticky?: boolean;
}

const NavBar: React.FC<INavBarProps> = ({
	classNames = [],
	className,
	children,
	background = 'light',
	logo,
	logoMobile,
	ctaMobile,
	logoURL,
	useCollapse = true,
	useOffcanvas = false,
	isSticky = true,
}) => {
	const ref = useRef(null);
	const collapseRef = useRef(null);
	let collapse: any = null;
	const isMobile = useMobileViewport(576);

	// Helper class name for sticky positioning
	useEffect(() => {
		if (!ref.current || isSticky !== true) {
			return;
		}

		const navbar = ref.current! as HTMLDivElement;
		const topOffset = navbar.offsetTop;

		const handleScroll = () => {
			if (window.scrollY > topOffset) {
				document.body.classList.add('navbar-sticky');
				document.body.style.paddingTop = `${navbar.clientHeight}px`;
			} else {
				document.body.classList.remove('navbar-sticky');
				document.body.style.paddingTop = '';
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		if (!collapseRef || !collapseRef.current) {
			return;
		}

		// @ts-ignore
		collapse = new Collapse(collapseRef.current, {
			toggle: false,
		});

		const handleClick = (e: any) => {
			const navEl = ref.current! as HTMLDivElement;
			const openedMegaMenus = navEl.querySelectorAll('.mega-menu.show');

			if (!navEl.contains(e.target) && openedMegaMenus.length > 0) {
				e.preventDefault();
			}
		};

		document.body.addEventListener('click', handleClick);

		return () => {
			document.body.removeEventListener('click', handleClick);
		};
	});

	// Handle offCanvas closing on link click
	useEffect(() => {
		if (!isMobile) return;

		const handleBodyClick = (event: any) => {
			const target = event.target;
			const offcanvas = target.closest('.offcanvas');
			const navbar = ref.current! as HTMLDivElement;
			if (
				target.classList.contains('nav-link') &&
				offcanvas &&
				target.getAttribute('href').includes('#')
			) {
				event.preventDefault();
				const targetId = target
					.getAttribute('href')
					.substring(target.getAttribute('href').indexOf('#')); // Grab the value with the #;

				const targetElement = document.querySelector(targetId);

				if (window.location.pathname !== target.pathname) {
					return (window.location.href = event.target.href); // If the link is not on the same page, redirect to the link
				}
				if (targetElement) {
					const navbarToggler = offcanvas.querySelector(
						'[data-bs-dismiss="offcanvas"]',
					);
					navbarToggler.click();

					// workaround to a known and unfixed bootstrap issue with offcanvas and interrupted scrolling https://github.com/bootscore/bootscore/issues/227
					setTimeout(() => {
						window.scrollTo({
							top: targetElement.offsetTop - navbar.clientHeight,
						});
					}, 500);
				}
			}
		};
		document.body.addEventListener('click', handleBodyClick);

		return () => {
			document.body.removeEventListener('click', handleBodyClick);
		};
	});

	return (
		<nav
			className={
				className ||
				cn(
					'navbar',
					'navbar-expand-lg',
					background &&
						background !== 'transparent' &&
						`navbar-${background} bg-${background}`,
					background === 'dark' && 'text-light',
					...classNames,
				)
			}
			ref={ref}
		>
			<div className="container-fluid">
				{logo && (
					<a className="navbar-brand" href={logoURL || '/'}>
						<Image
							{...logo}
							className={
								logoMobile ? 'd-none d-lg-inline-block' : ''
							}
						/>
						{logoMobile && (
							<Image
								{...logoMobile}
								className="d-inline-block d-lg-none"
							/>
						)}
					</a>
				)}
				{ctaMobile && (
					<Button
						{...ctaMobile}
						type="primary"
						classNames={[
							'btn-cta',
							'd-inline-block',
							'd-lg-none',
							'ms-auto',
						]}
					/>
				)}
				{useCollapse && (
					<button
						className="navbar-toggler"
						type="button"
						onClick={() => collapse.toggle()}
						aria-label="Toggle"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
				)}
				{useOffcanvas && (
					<OffcanvasButton className="navbar-toggler">
						<span className="navbar-toggler-icon"></span>
					</OffcanvasButton>
				)}

				<div
					className={cn(['collapse', 'navbar-collapse'])}
					ref={collapseRef}
				>
					{children}
				</div>
			</div>
		</nav>
	);
};

export default NavBar;

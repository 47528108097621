import AlternateTextImage from './src/components/AlternateTextImage/index';
import AmenitiesCard from './src/components/Amenities/AmenitiesCard';
import Amenities from './src/components/Amenities/index';
import CommunityCard from './src/components/Community/CommunityCard';
import Community from './src/components/Community/index';
import Container from './src/components/Container/index';
import CTABar from './src/components/CTABar/index';
import CTABarWithImage from './src/components/CTABarWithImage/index';
import CustomButton from './src/components/CustomButton/index';
import Divider from './src/components/Divider/index';
import Footer from './src/components/Footer/index';
import Form from './src/components/Form/index';
import FullWidthGallery from './src/components/FullWidthGallery/index';
import Hero from './src/components/Hero/index';
import Modal from './src/components/Modal/index';
import NavBar from './src/components/NavBar/index';
import Spacer from './src/components/Spacer/index';
import SplitHero from './src/components/SplitHero/index';
import TestimonialsCarousel from './src/components/TestimonialsCarousel/index';
import Nav from './node_modules/tbk-components/src/components/Nav/index.js';
import NavItem from './node_modules/tbk-components/src/components/Nav/NavItem.js';
import Offcanvas from './node_modules/tbk-components/src/components/Offcanvas/Offcanvas.js';
import OffcanvasBackButton from './node_modules/tbk-components/src/components/Offcanvas/OffcanvasBackButton.js';
import OffcanvasButton from './node_modules/tbk-components/src/components/Offcanvas/OffcanvasButton.js';
import RawHTML from './node_modules/tbk-components/src/components/RawHTML/index.js';

const components = {
	AlternateTextImage,
	AmenitiesCard,
	Amenities,
	CommunityCard,
	Community,
	Container,
	CTABar,
	CTABarWithImage,
	CustomButton,
	Divider,
	Footer,
	Form,
	FullWidthGallery,
	Hero,
	Modal,
	NavBar,
	Spacer,
	SplitHero,
	TestimonialsCarousel,
	Nav,
	NavItem,
	Offcanvas,
	OffcanvasBackButton,
	OffcanvasButton,
	RawHTML
};

export default components;

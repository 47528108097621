import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { ILink } from 'tbk-components/src/components/Button';
import Nav, { NavItem } from 'tbk-components/src/components/Nav';
import SocialIcons, {
	ISocialIconsProps,
} from 'tbk-components/src/components/SocialIcons';

export interface IFooterProps extends IBaseElementProps {
	logo?: IImage;
	homeLink?: ILink;
	menuPrimary?: NavItem[];
	menuPrimaryMobile?: NavItem[];
	menuSecondary?: NavItem[];
	menuSecondaryMobile?: NavItem[];
	menuCalloutsMobile?: NavItem[];
	address?: string;
	copyrightText?: string;
	socialIcons?: ISocialIconsProps;
}

/**
 * Footer
 * @requiresHooks
 * @icon layout
 */
const Footer: React.FC<IFooterProps> = ({
	className,
	classNames = [],
	homeLink,
	logo,
	menuPrimary,
	menuPrimaryMobile,
	menuSecondary,
	menuSecondaryMobile,
	menuCalloutsMobile,
	address,
	copyrightText,
	socialIcons,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	return (
		<footer ref={ref} className={className || cn('footer', ...classNames)}>
			<div className="footer__container">
				{homeLink && (
					<a
						href={homeLink.href}
						title={logo ? homeLink.label : ''}
						className="footer__logo"
					>
						{logo ? (
							<Image {...logo} alt={logo.alt || homeLink.label} />
						) : (
							homeLink.label
						)}
					</a>
				)}

				{(menuPrimary || address) && (
					<div className="footer__menu-address">
						{menuPrimary && (
							<Nav
								navItems={menuPrimary}
								collapseEnabled={false}
								dropdownEnabled={false}
								classNames={['footer__menu-primary']}
							/>
						)}
						{address && (
							<address
								className="footer__address"
								dangerouslySetInnerHTML={{ __html: address }}
							/>
						)}
					</div>
				)}

				{menuSecondary && (
					<Nav
						navItems={menuSecondary}
						collapseEnabled={false}
						dropdownEnabled={false}
						classNames={['footer__menu-secondary']}
					/>
				)}

				<div className="footer__legal">
					{copyrightText && (
						<div className="footer__copyright">{copyrightText}</div>
					)}

					{socialIcons && <SocialIcons {...socialIcons} />}

					<a
						href="https://tbkcreative.com/"
						target="_blank"
						className="footer__tbk"
						rel="noreferrer"
					>
						Web Design &amp; Digital Marketing{' '}
						<span>tbk Creative</span>
					</a>
				</div>
			</div>
			<div className="footer__mobile-navigation footer__mobile-navigation__container">
				{menuPrimaryMobile && (
					<div className="footer__menu-address">
						{menuPrimary && (
							<Nav
								navItems={menuPrimaryMobile}
								collapseEnabled={true}
								dropdownEnabled={false}
								classNames={['footer__menu-primary-mobile']}
							/>
						)}
					</div>
				)}
				<div className="footer__mobile-address-logo">
					<div className="footer__mobile-address">
						{address && (
							<address
								className="footer__address"
								dangerouslySetInnerHTML={{ __html: address }}
							/>
						)}
					</div>
					<div className="footer__mobile-logo">
						{homeLink && (
							<a
								href={homeLink.href}
								title={logo ? homeLink.label : ''}
								className="footer__logo"
							>
								{logo ? (
									<Image
										{...logo}
										alt={logo.alt || homeLink.label}
									/>
								) : (
									homeLink.label
								)}
							</a>
						)}
					</div>
				</div>
				<div className="footer__mobile-secondary-nav">
					{menuSecondaryMobile && (
						<Nav
							navItems={menuSecondaryMobile}
							collapseEnabled={false}
							dropdownEnabled={false}
							classNames={['footer__menu-secondary']}
						/>
					)}
				</div>
				<div className="footer__mobile-callout-menu">
					{menuCalloutsMobile && (
						<Nav
							navItems={menuCalloutsMobile}
							collapseEnabled={false}
							dropdownEnabled={false}
							classNames={[
								'footer__menu-secondary footer__menu-callouts',
							]}
						/>
					)}
				</div>
				<div className="footer__mobile-social">
					{socialIcons && <SocialIcons {...socialIcons} />}
				</div>
				<div className="footer__mobile-legal">
					{copyrightText && (
						<div className="footer__copyright">{copyrightText}</div>
					)}
					<a
						href="https://tbkcreative.com/?ref=goodwill"
						target="_blank"
						className="footer__tbk"
						rel="noreferrer"
					>
						Web Design &amp; Digital Marketing{' '}
						<span>tbk Creative</span>
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

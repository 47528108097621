import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { ICustomizationOptions } from '../../types';

export interface IContainerProps extends IBaseElementProps {
	children?: any;
	size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fluid';
	sectionBackgroundColor?: ICustomizationOptions['sectionBackgroundColor'];
}

/**
 * Container
 * @block
 * @icon shortcode
 */
const Container: React.FC<IContainerProps> = ({
	children,
	className,
	classNames = [],
	size,
	sectionBackgroundColor = 'white',
}) => (
	<div
		className={
			className ||
			cn(
				'montfort-container',
				`bg-${sectionBackgroundColor}`,
				...classNames,
			)
		}
	>
		<div
			className={cn(
				'montfort-container__content',
				size && `montfort-container__content-${size}`,
			)}
		>
			{children}
		</div>
	</div>
);

export default Container;

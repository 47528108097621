import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import { IImage } from 'tbk-components/src/components/Image';
import CustomButton, { ILink } from '../CustomButton';
import { ICustomizationOptions } from '../../types';

export interface ICTABarWithImageProps
	extends ICustomizationOptions,
		IBaseElementProps {
	sectionId?: string;

	image?: IImage;
	message?: string;
	heading?: string;
	CTALink?: ILink;
	/**
	 * @control uniqueid
	 * @prefix testimonials-carousel
	 */
	id?: string;
}

/**
 * CTA Bar with Image
 * @block
 * @requiresHooks
 * @icon button
 */
const CTABarWithImage: React.FC<ICTABarWithImageProps> = ({
	className,
	classNames = [],
	message,
	sectionId,
	image,
	CTALink,
	heading,
	buttonColor = 'primary',
	textColor = 'black',
	sectionBackgroundColor = 'white',
}) => {
	return (
		<div
			className={className || cn('cta-bar-with-image', ...classNames)}
			id={sectionId}
		>
			<div
				className="cta-bar-with-image__container"
				style={{
					backgroundImage: `url(${image?.src})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center top',
				}}
			>
				<div
					className={`cta-bar-with-image__content bg-${sectionBackgroundColor}`}
				>
					{heading && (
						<h2
							className={`cta-bar-with-image__heading text-${textColor}`}
						>
							{heading}
						</h2>
					)}
					{message && (
						<h3
							className={`cta-bar-with-image__message text-${textColor}`}
						>
							{message}
						</h3>
					)}
					{CTALink && (
						<CustomButton
							color={buttonColor}
							classNames={['cta-bar-with-image__button']}
							{...CTALink}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default CTABarWithImage;

import React, { useEffect, useState } from 'react';

const useMobileViewport = (threshold = 768) => {
	const [isMobileViewport, setIsMobileViewport] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileViewport(window.innerWidth <= threshold);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [threshold]);

	return isMobileViewport;
};

export default useMobileViewport;

import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import CommunityCard, { ICommunityCardProps } from './CommunityCard';
import type { ICustomizationOptions } from '../../types';

export interface ICommunityProps
	extends ICustomizationOptions,
		Omit<IBaseElementProps, 'id'> {
	sectionId?: string;
	heading?: string;
	/**
	 * @control richtext
	 */
	paragraph?: string;
	communityCards?: ICommunityCardProps[];
}

/**
 * Community
 * @block
 * @requiresHooks
 * @icon admin-users
 */
const Community: React.FC<ICommunityProps> = ({
	sectionId,
	className,
	classNames = [],
	heading,
	paragraph,
	textColor = 'black',
	buttonColor = 'primary',
	sectionBackgroundColor = 'white',
	communityCards = [],
}) => (
	<div
		className={
			className ||
			cn('community', `bg-${sectionBackgroundColor}`, ...classNames)
		}
		id={sectionId}
	>
		<div className="community__container">
			<div className="community__headings">
				{heading && (
					<h2 className={`community__heading text-${textColor}`}>
						{heading}
					</h2>
				)}
				{paragraph && (
					<div
						className={`community__paragraph text-${textColor}`}
						dangerouslySetInnerHTML={{ __html: paragraph }}
					/>
				)}
			</div>
			<div className="community__content">
				{Array.isArray(communityCards) &&
					communityCards.length > 0 &&
					communityCards.map((card, index) => (
						<CommunityCard
							key={index}
							textColor={textColor}
							buttonColor={buttonColor}
							{...card}
						/>
					))}
			</div>
		</div>
	</div>
);

export default Community;

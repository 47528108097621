import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface IDividerProps extends IBaseElementProps {}

/**
 * Divider
 * @block
 * @icon image-flip-vertical
 */
const Divider: React.FC<IDividerProps> = ({ className, classNames = [] }) => {
	return <div className={className || cn('divider', ...classNames)}></div>;
};

export default Divider;

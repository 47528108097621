import React, { AnchorHTMLAttributes } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Icon from 'tbk-components/src/components/Icon';

export interface ICustomButtonProps
	extends IBaseElementProps,
		AnchorHTMLAttributes<HTMLAnchorElement> {
	buttonType?: 'Button' | 'Link';
	size?: 'sm' | 'lg';
	color?: 'primary' | 'secondary';
	href?: string;
	label?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	children?: any;
	onClick?: (event: any) => any;
	icon?: string;
}

export interface ILink {
	href?: string;
	label?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	children?: any;
}

/**
 * Custom Buttom
 * @requiresHooks
 * @icon button
 */
const CustomButton: React.FC<ICustomButtonProps> = ({
	className,
	classNames = [],
	href,
	label,
	icon,
	children,
	buttonType = 'Button',
	size = 'lg',
	target,
	color = 'primary',
	onClick,
}) => (
	<a
		href={href}
		className={
			className ||
			cn(
				'custom-button',
				buttonType ? `custom-button__${buttonType.toLowerCase()}` : '',
				{
					'button-lg': buttonType === 'Button' && size === 'lg',
					'button-sm': buttonType === 'Button' && size === 'sm',
				},
				buttonType === 'Button' && `bg-${color}`,
				buttonType === 'Link' && `text-${color}`,
				...classNames,
			)
		}
		onClick={onClick}
		target={target || null}
		rel="noopener"
	>
		<span>
			{label}
			{children}
		</span>
		{icon && <Icon name={icon} />}
	</a>
);

export default CustomButton;

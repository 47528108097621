import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import CustomButton, { ILink } from '../CustomButton';
import { IVideo } from 'tbk-components/src/components/Video';
import Icon from 'tbk-components/src/components/Icon';
import { ICustomizationOptions } from '../../types';
import useMobileViewport from '../../utils/useMobileViewport';

export interface IHeroProps extends ICustomizationOptions, IBaseElementProps {
	sectionId?: string;
	heading?: string;
	message?: string;
	CTALink?: ILink;
	/**
	 * @size resize
	 */
	backgroundImage?: IImage;
	backgroundVideo?: IVideo;
	/**
	 * @control uniqueid
	 * @prefix testimonials-carousel
	 */
	id?: string;
}

/**
 * Hero
 * @block
 * @requiresHooks
 * @icon video-alt3
 */
const Hero: React.FC<IHeroProps> = ({
	className,
	classNames = [],
	message,
	sectionId,
	backgroundImage,
	backgroundVideo,
	CTALink,
	heading,
	buttonColor = 'primary',
	textColor = 'black',
	sectionBackgroundColor = 'white',
}) => {
	const [isVideoPlaying, setIsVideoPlaying] = useState(true);
	const videoRef = React.createRef<HTMLVideoElement>();
	const backgroundImageStyles = !backgroundVideo?.src
		? { backgroundImage: `url(${backgroundImage?.src})` }
		: {};
	const isMobile = useMobileViewport(992);
	const containerRef = React.createRef<HTMLDivElement>();

	useEffect(() => {
		if (!backgroundVideo?.src) return;
		const videoElement = videoRef.current;
		if (videoElement) {
			if (isVideoPlaying) {
				videoElement.play();
			} else {
				videoElement.pause();
			}
		}
	}, [isVideoPlaying, backgroundVideo]);

	// Making the hero's height dynamic to make sure CTA stays above the fold
	useEffect(() => {
		if (!containerRef.current) {
			return;
		}

		const container = containerRef.current as HTMLDivElement;
		const offsetHeight = container.offsetHeight;

		if (isMobile) {
			container.style.padding = '';
			return;
		} else {
			container.style.padding = `calc(85vh - ${offsetHeight}px) 0 0`;
		}
	}, [containerRef]);

	return (
		<div className={className || cn('hero', ...classNames)} id={sectionId}>
			<div
				ref={containerRef}
				className={cn(
					'hero__container',
					!backgroundVideo?.src && 'hero__container--bg-image',
				)}
				style={backgroundImageStyles}
			>
				{backgroundVideo?.src && (
					<video
						className="hero__video"
						src={backgroundVideo?.src}
						ref={videoRef}
						loop
						muted
						playsInline
						autoPlay
					/>
				)}
				{backgroundVideo?.src && (
					<button
						className="hero__play-pause-button"
						onClick={() => setIsVideoPlaying(!isVideoPlaying)}
					>
						{' '}
						<Icon name={isVideoPlaying ? 'pause' : 'play'} />
						{isVideoPlaying ? 'Pause Video' : 'Play Video'}
					</button>
				)}
				<div className={`hero__content bg-${sectionBackgroundColor}`}>
					{heading && (
						<h1 className={`hero__heading text-${textColor}`}>
							{heading}
						</h1>
					)}
					{message && (
						<h3 className={`hero__message text-${textColor}`}>
							{message}
						</h3>
					)}
					{CTALink && (
						<CustomButton
							color={buttonColor}
							classNames={['hero__button']}
							{...CTALink}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Hero;

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { ILink } from 'tbk-components/src/components/Button';
import CustomButton from '../CustomButton';
import useMobileViewport from '../../utils/useMobileViewport';
import { ICustomizationOptions } from '../../types';

export interface ISplitHeroProps
	extends ICustomizationOptions,
		Omit<IBaseElementProps, 'id'> {
	sectionId?: string;
	heading?: string;
	paragraph?: string;
	address?: string;
	link?: ILink;
	phoneNumber?: string;
	image?: IImage;
	contentPosition?: 'left' | 'right';
}

/**
 * Split Hero
 * @block
 * @requiresHooks
 * @icon image-flip-horizontal
 */
const SplitHero: React.FC<ISplitHeroProps> = ({
	heading,
	paragraph,
	address,
	link,
	phoneNumber,
	image,
	className,
	contentPosition = 'right',
	sectionBackgroundColor = 'white',
	buttonColor = 'primary',
	textColor = 'black',
	classNames = [],
	sectionId,
}) => {
	const isMobile = useMobileViewport(1200);

	let flexDirection;

	if (!isMobile) {
		flexDirection =
			contentPosition === 'right' ? 'flex-row-reverse' : 'flex-row';
	} else {
		flexDirection =
			contentPosition === 'right' ? 'flex-column-reverse' : 'flex-column';
	}

	return (
		<div
			className={
				className ||
				cn(
					'split-hero',
					`split-hero__content--${contentPosition}`,
					`bg-${sectionBackgroundColor}`,
					...classNames,
				)
			}
			id={sectionId}
		>
			<div className={`split-hero__container${' ' + flexDirection}`}>
				<div className="split-hero__content">
					<div className="split-hero__top-content">
						{heading && (
							<h2
								className={`split-hero__heading text-${textColor}`}
							>
								{heading}
							</h2>
						)}
						{paragraph && (
							<div
								className={`split-hero__paragraph text-${textColor}`}
								dangerouslySetInnerHTML={{ __html: paragraph }}
							/>
						)}
					</div>
					<div className="split-hero__bottom-content">
						{address && (
							<div
								className={`split-hero__address text-${textColor}`}
							>
								{address}
							</div>
						)}
						{link && (
							<CustomButton
								{...link}
								classNames={['split-hero__link']}
								buttonType="Link"
								icon="arrowUpRight"
								color={buttonColor}
							/>
						)}
						{phoneNumber && (
							<a
								className={`split-hero__phone-number text-${textColor}`}
								href={`tel:${phoneNumber}`}
							>
								{phoneNumber}
							</a>
						)}
					</div>
				</div>
				<div className="split-hero__image">
					<Image {...image} />
				</div>
			</div>
		</div>
	);
};

export default SplitHero;

import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';

export interface ISpacerProps extends IBaseElementProps {
	/**
	 * Spacer Size
	 */
	size:
		| 'Default'
		| 'Extra Small'
		| 'Extra Small on Mobile'
		| 'Small'
		| 'Medium'
		| 'Large on Desktop';
}

/**
 * Spacer
 * @block
 * @icon move
 */
const Spacer: React.FC<ISpacerProps> = ({
	size,
	className,
	classNames = [],
}) => (
	<div
		className={cn(
			'spacer',
			size === 'Extra Small on Mobile' && 'spacer--mobile-small',
			size === 'Extra Small' && 'spacer--extra-small',
			size === 'Small' && 'spacer--small',
			size === 'Medium' && 'spacer--medium',
			size === 'Large on Desktop' && 'spacer--large',
		)}
	></div>
);

export default Spacer;

import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';

export interface IAmenitiesCardProps {
	image?: IImage;
	title?: string;
	description?: string;
}

const AmenitiesCard: React.FC<IAmenitiesCardProps> = ({
	image,
	title,
	description,
}) => (
	<div className="amenities-card">
		<div className="amenities-card__container">
			<div className="amenities-card__image">
				<Image {...image} />
			</div>

			<div className="amenities-card__content">
				<h3 className="amenities-card__title">{title}</h3>
				<p className="amenities-card__description">{description}</p>
			</div>
		</div>
	</div>
);

export default AmenitiesCard;

import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import { ILink } from 'tbk-components/src/components/Button';
import CustomButton from '../CustomButton';
import type { ICustomizationOptions } from '../../types';

export interface ICommunityCardProps {
	image?: IImage;
	title?: string;
	link?: ILink;
	buttonColor?: ICustomizationOptions['buttonColor'];
	textColor?: ICustomizationOptions['textColor'];
}

const CommunityCard: React.FC<ICommunityCardProps> = ({
	image,
	link,
	title,
	buttonColor = 'primary',
	textColor = 'black',
}) => (
	<div className="community-card">
		<div className="community-card__container">
			<div className="community-card__image">
				<Image {...image} />
			</div>
			<div className="community-card__content">
				<h4 className={`community-card__title text-${textColor}`}>
					{title}
				</h4>
				<CustomButton
					{...link}
					buttonType="Link"
					icon="arrowUpRight"
					color={buttonColor}
				/>
			</div>
		</div>
	</div>
);

export default CommunityCard;

import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Loading from 'tbk-components/src/components/Loading';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import cn from 'classnames';

export interface IModalI18n {
	submit: string;
	cancel: string;
}

export interface IModalProps extends IBaseElementProps {
	id?: string;
	title?: string;
	showSubmit?: boolean;
	showCancel?: boolean;
	showClose?: boolean;
	onSubmit?: (e: any) => void;
	onCancel?: (e: any) => void;
	busy?: boolean;
	i18n?: IModalI18n;
	className?: string;
	modalClassNames?: string[];
	headerClassNames?: string[];
	bodyClassNames?: string[];
	footerClassNames?: string[];
	noScrollBody?: boolean;
	showBackdrop?: boolean;
	closeOnOutsideClick?: boolean;
	closeOnEscape?: boolean;
}
const Modal: React.FC<IModalProps> = ({
	id = 'amenities-modal',
	title,
	showSubmit = false,
	showCancel = false,
	onSubmit = () => {},
	onCancel = () => {},
	busy,
	i18n = {
		submit: 'Submit',
		cancel: 'Cancel',
	} as IModalI18n,
	children,
	className,
	modalClassNames = ['custom-modal-dialog'],
	headerClassNames = ['custom-modal-header'],
	bodyClassNames = ['custom-modal-body'],
	footerClassNames = ['custom-modal-footer'],
	noScrollBody = true,
	showBackdrop = true,
	closeOnOutsideClick = true,
	closeOnEscape = true,
}) => {
	useEffect(() => {
		if (noScrollBody) {
			document.body.classList.add('overflow-hidden');
			return () => {
				document.body.classList.remove('overflow-hidden');
			};
		}
	}, [noScrollBody]);

	useEffect(() => {
		if (closeOnOutsideClick) {
			const listener = (e: any) => {
				// The first version is if they've clicked on the modal itself ( looks like the backdrop )
				if (
					e.target.classList.contains('modal') ||
					!e.target.closest('.modal')
				) {
					onCancel(e);
				}
			};
			document.addEventListener('click', listener);
			return () => {
				document.removeEventListener('click', listener);
			};
		}
	}, [closeOnOutsideClick, onCancel]);

	useEffect(() => {
		if (closeOnEscape) {
			const listener = (e: any) => {
				if (27 === e.which) {
					onCancel(e);
				}
			};
			document.addEventListener('keyup', listener);
			return () => {
				document.removeEventListener('keyup', listener);
			};
		}
	}, [closeOnEscape, onCancel]);

	return ReactDOM.createPortal(
		<>
			<div
				className={`modal fade show ${className || ''}`}
				style={{ display: 'block' }}
				id={id}
				aria-labelledby={id + 'label'}
				aria-hidden="false"
				tabIndex={-1}
			>
				<div className={cn('modal-dialog', modalClassNames)}>
					<div
						className="modal-content"
						style={{ overflow: 'visible' }}
					>
						<div className={cn('modal-header', headerClassNames)}>
							{title && (
								<h5 className="modal-title" id={id + 'label'}>
									{title}
								</h5>
							)}
							<button
								type="button"
								className="btn-close"
								onClick={onCancel}
								aria-label={i18n.cancel}
							/>
						</div>
						<div className={cn('modal-body', bodyClassNames)}>
							{children}
						</div>
						{(showCancel || showSubmit) && (
							<div
								className={cn('modal-footer', footerClassNames)}
							>
								{busy && <Loading inline />}
								{showCancel && (
									<button
										type="button"
										disabled={busy}
										className="btn btn-secondary"
										onClick={onCancel}
									>
										{i18n.cancel}
									</button>
								)}
								{showSubmit && (
									<button
										type="button"
										disabled={busy}
										className="btn btn-primary"
										onClick={onSubmit}
									>
										{i18n.submit}
									</button>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{showBackdrop && <div className="modal-backdrop fade show" />}
		</>,
		document.body,
	);
};

export default Modal;

import React, { useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import RawHTML from 'tbk-components/src/components/RawHTML';
import { ICustomizationOptions } from '../../types';

export interface IFormProps extends IBaseElementProps {
	sectionId?: string;
	children?: any;
	sectionBackgroundColor?: ICustomizationOptions['sectionBackgroundColor'];
}

/**
 * Form
 * @block
 * @requiresHooks
 */

const Form: React.FC<IFormProps> = ({
	className,
	classNames = [],
	children,
	sectionId,
	sectionBackgroundColor = 'white',
}) => {
	const ref = useRef<HTMLDivElement>(null);

	//form seems to be rendered with extra empty <p> tags when using this wrapper, this removes them
	const removeEmptyParagraphs = (content) => {
		const modifiedContent = React.Children.map(content, (child) => {
			if (
				child.type !== 'p' ||
				(child.type === 'p' && child.props.children?.length > 0)
			) {
				return child;
			}
			return null;
		});

		return modifiedContent;
	};

	const modifiedChildren = removeEmptyParagraphs(children);

	// Adds focus classes to fields wrappers
	useEffect(() => {
		let fields;

		const handleFocus = (event: FocusEvent) => {
			const el = event.target as HTMLElement;
			const target =
				el.closest('.gform-grid-col') || el.closest('.gfield');

			target?.classList.add('input-focused');
		};

		const handleBlur = (event: FocusEvent) => {
			const el = event.target as HTMLElement;
			const target =
				el.closest('.gform-grid-col') || el.closest('.gfield');

			target?.classList.remove('input-focused');
		};

		const handleChange = (event: any) => {
			const target = event.target as HTMLInputElement;
			const { value } = target;
			const wrapper =
				target.closest('.gform-grid-col') || target.closest('.gfield');
			if (value) {
				wrapper?.classList.add('input-filled');
			} else {
				wrapper?.classList.remove('input-filled');
			}
		};

		const setListeners = () => {
			fields = ref.current?.querySelectorAll(
				'input, textarea, select',
			) as NodeListOf<HTMLInputElement>;

			fields.forEach((field) => {
				field.addEventListener('focus', handleFocus);
				field.addEventListener('blur', handleBlur);
				field.addEventListener('change', handleChange);

				handleChange({
					target: field,
				});
			});

			// The listeners stop working if the form is handled with ajax, they work fine on initial load but not after the form is re-rendered following errors for example, so this added class handles the label transition on the fields and when listeners stop working we avoid the overlap between label and imput text.
			const allFields = ref.current?.querySelectorAll(
				'.gfield--type-text, .gfield--type-email, .gfield--type-textarea, .gfield--type-phone',
			) as NodeListOf<HTMLInputElement>;
			allFields.forEach((field) => {
				field.classList.add('input-initialized');
			});
		};

		const { document } = window;
		// listeners need to be added once children are rendered
		// check if readyState is already set, ContentLoaded already fired. This is for Modals.
		if (document.readyState !== 'loading') {
			setListeners();
		} else {
			document.addEventListener('DOMContentLoaded', setListeners);
		}

		return () => {
			document.removeEventListener('DOMContentLoaded', setListeners);

			fields?.forEach((field) => {
				field.removeEventListener('focus', handleFocus);
				field.removeEventListener('blur', handleBlur);
				field.removeEventListener('change', handleChange);
			});
		};
	}, []);
	return (
		<div
			className={
				className ||
				cn(
					'form-wrapper',
					`bg-${sectionBackgroundColor}`,
					...classNames,
				)
			}
			ref={ref}
			id={sectionId}
		>
			{modifiedChildren}
		</div>
	);
};

export default Form;

import React, { Fragment } from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Carousel, { CarouselItem } from 'tbk-components/src/components/Carousel';
import AmenitiesCard, { IAmenitiesCardProps } from './AmenitiesCard';
import CustomButton from '../CustomButton';
import Modal from '../Modal';
import FullWidthGallery from '../FullWidthGallery';
import { IImage } from 'tbk-components/src/components/Image';
import { ICustomizationOptions } from '../../types';

export interface IAmenitiesProps extends IBaseElementProps {
	sectionId?: string;
	/**
	 * @control uniqueid
	 * @prefix amenities
	 */
	id?: string;
	heading?: string;
	/**
	 * @control richtext
	 */
	paragraph?: string;
	amenitiesCards?: IAmenitiesCardProps[];
	fullScreenGalleryImages?: IImage[];
	galleryButtonLabel?: string;
	buttonColor?: ICustomizationOptions['buttonColor'];
}

const carouselOptions = {
	type: 'slider',
	rewind: false,
	bound: true,
	gap: 32,
	perView: 3,
	showBullets: false,
	peek: {
		before: 0,
		after: 100,
	},
	breakpoints: {
		576: {
			perView: 1,
			peek: {
				before: 0,
				after: 100,
			},
		},
		768: {
			perView: 2,
			peek: {
				before: 0,
				after: 100,
			},
		},
		992: {
			perView: 2,
			peek: {
				before: 0,
				after: 100,
			},
		},
	},
};

/**
 * Amenities
 * @block
 * @requiresHooks
 * @icon images-alt
 */
const Amenities: React.FC<IAmenitiesProps> = ({
	className,
	classNames = [],
	heading,
	paragraph,
	amenitiesCards = [],
	galleryButtonLabel,
	fullScreenGalleryImages = [],
	id,
	sectionId,
	buttonColor = 'primary',
}) => {
	const [modalIsOpen, setIsOpen] = React.useState(false);
	return (
		<Fragment>
			<div
				className={className || cn('amenities', ...classNames)}
				id={sectionId}
			>
				<div className="amenities__container">
					<div className="amenities__headings">
						{heading && (
							<h2 className="amenities__heading">{heading}</h2>
						)}
						{paragraph && (
							<div
								className="amenities__paragraph"
								dangerouslySetInnerHTML={{ __html: paragraph }}
							/>
						)}
					</div>
					<div className="amenities__content">
						<Carousel options={carouselOptions} id={id}>
							{Array.isArray(amenitiesCards) &&
								amenitiesCards.length > 0 &&
								amenitiesCards.map((card, index) => (
									<CarouselItem key={index}>
										<AmenitiesCard {...card} />
									</CarouselItem>
								))}
						</Carousel>
					</div>
					{Array.isArray(fullScreenGalleryImages) &&
						fullScreenGalleryImages.length > 0 && (
							<CustomButton
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setIsOpen(true);
								}}
								label={galleryButtonLabel}
								classNames={['amenities__gallery-button']}
								buttonType="Button"
								color={buttonColor}
								href="#"
							/>
						)}
				</div>
			</div>
			{modalIsOpen && (
				<Modal
					onCancel={(e) => {
						e.stopPropagation();
						setIsOpen(false);
					}}
				>
					<FullWidthGallery
						images={fullScreenGalleryImages}
						id={id}
					/>
				</Modal>
			)}
		</Fragment>
	);
};

export default Amenities;

import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Image, { IImage } from 'tbk-components/src/components/Image';
import CustomButton, { ILink } from '../CustomButton';
import { ICustomizationOptions } from '../../types';

export interface ICTABarProps extends IBaseElementProps, ICustomizationOptions {
	sectionId?: string;
	message?: string;
	image?: IImage;
	CTALink?: ILink;
	/**
	 * @control uniqueid
	 * @prefix testimonials-carousel
	 */
	id?: string;
}

/**
 * CTA Bar
 * @block
 * @requiresHooks
 * @icon button
 */
const CTABar: React.FC<ICTABarProps> = ({
	className,
	classNames = [],
	message,
	sectionId,
	image,
	CTALink,
	sectionBackgroundColor = 'secondary',
	textColor = 'black',
	buttonColor = 'primary',
}) => {
	return (
		<div
			className={className || cn('cta-bar', ...classNames)}
			id={sectionId}
		>
			<div className={`cta-bar__container bg-${sectionBackgroundColor}`}>
				{image && <Image className="cta-bar__image" {...image} />}
				{message && (
					<h3 className={`cta-bar__message text-${textColor}`}>
						{message}
					</h3>
				)}
				{CTALink && (
					<CustomButton
						classNames={['cta-bar__button']}
						color={buttonColor}
						{...CTALink}
					/>
				)}
			</div>
		</div>
	);
};

export default CTABar;

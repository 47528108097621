import React from 'react';
import cn from 'classnames';
import { IBaseElementProps } from 'tbk-components/src/components/BasicElement';
import Carousel from 'tbk-components/src/components/Carousel';
import CarouselItem from 'tbk-components/src/components/CarouselItem';
import Blockquote from 'tbk-components/src/components/Blockquote';
import { ICustomizationOptions } from '../../types';

export interface ITestimonial {
	/**
	 * @control textarea
	 */
	text?: string;
	author?: string;
}

export interface ITestimonialsCarouselProps extends IBaseElementProps {
	sectionId?: string;
	heading?: string;
	testimonials?: ITestimonial[];
	sectionBackgroundColor?: ICustomizationOptions['sectionBackgroundColor'];
	/**
	 * @control uniqueid
	 * @prefix testimonials-carousel
	 */
	id?: string;
}

/**
 * Testimonials Carousel
 * @block
 * @requiresHooks
 * @icon editor-quote
 */
const TestimonialsCarousel: React.FC<ITestimonialsCarouselProps> = ({
	testimonials,
	className,
	classNames = [],
	heading,
	sectionBackgroundColor = 'white',
	id,
	sectionId,
}) => {
	return (
		<div
			className={
				className ||
				cn(
					'testimonials-carousel',
					`bg-${sectionBackgroundColor}`,
					...classNames,
				)
			}
			id={sectionId}
		>
			<h2 className="testimonials-carousel__heading">{heading}</h2>
			<Carousel showArrows={true} id={id}>
				{(testimonials || []).map(({ text, author }, index) => (
					<CarouselItem key={index}>
						<Blockquote source={author}>{text}</Blockquote>
					</CarouselItem>
				))}
			</Carousel>
		</div>
	);
};

export default TestimonialsCarousel;
